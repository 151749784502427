<template>
  <div class="invite-view tw-mb-4">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "InviteView"
};
</script>

<style lang="scss" scoped></style>
